.services {
  width: 100%;
  height: auto;

  .productwrap {
    min-height: 400px;
    max-height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .divider {
      box-sizing: border-box;
      width: 100%;
      padding: 50px 30px;
      margin: 0 auto;
      height: auto;
      // background: rebeccapurple;

      display: grid;
      grid-template-columns: 300px 1fr;
      gap: 1rem;

      .catsec {
        width: 100%;
        box-shadow: 0 0 3px 1px #80808069;
        border-radius: var(--border-radius);
        padding: 20px;
        box-sizing: border-box;

        .categoryname {
          cursor: pointer;
          margin-bottom: 10px;
          padding: 8px 10px;

          p {
            padding: 5px 10px;
          }
        }

        .activecategory {
          background: var(--color-red);
          border-radius: var(--border-radius);
          padding: 8px 10px;
          cursor: pointer;
          margin-bottom: 10px;
          color: white;

          p {
            margin-bottom: 0px;
          }
        }

        .subCatWrapper {
          background: #8080801a;
          border-radius: 5px;
          padding-left: 20px;

          .singleSubCategory {
            cursor: pointer;
            margin-bottom: 10px;
            padding: 8px 10px;
          }
        }
      }

      .productsec {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        .seoBoxContainer {
          display: flex;
          grid-column: span 2;
          box-shadow: 0 0 3px 1px #80808069;
          padding: 30px;
          border-radius: var(--border-radius);
          height: auto;
          overflow-y: scroll;
          flex-direction: column;
          max-height: 300px;

          // &::after {
          //   content: "s";
          //   background: rebeccapurple;
          // }
          h1,
          h2 {
            color: var(--color-red);
            font-size: 26px;
          }

          p {
            text-align: justify;
            hyphens: auto;
            letter-spacing: 0.01em;

            &:nth-last-child(1) {
              margin-bottom: 0px;
            }
          }
        }

        .productbox {
          display: flex;
          flex-direction: column;
          box-shadow: 0 0 3px 1px #80808069;
          padding: 30px;
          border-radius: var(--border-radius);
          justify-content: space-between;

          a {
            text-decoration: none;

            .heading {
              color: var(--color-red);
              font-size: 22px;
              font-weight: 600;
              text-transform: uppercase;
              margin: 0px;
            }
          }
          .heading {
            color: var(--color-red);
            font-size: 22px;
            font-weight: 600;
            text-transform: uppercase;
            margin: 0px;
          }

          .desc {
            font-size: 16px;
            line-height: 1.5em;
            text-align: justify;
            hyphens: auto;
            letter-spacing: 0.01em;
            margin: 10px 0px 15px 0px;
          }
        }

        .button {
          // width: 50%;
          padding: 8px 0px;
          font-weight: bold;
          background: var(--color-red);
          border: 1px solid var(--color-red);
          border-radius: var(--border-radius);
          color: #fff;
          outline: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1028px) {
  .services .productwrap .divider {
    grid-template-columns: 250px 1fr;
  }
}

@media only screen and (max-width: 936px) {
  .services .productwrap .divider {
    grid-template-columns: 1fr;
  }

  .services .productwrap .divider .catsec .activecategory {
    margin-bottom: 0px;
  }

  .services .productwrap .divider .catsec .categoryname {
    margin-bottom: 0px;
  }

  .services .productwrap .divider .catsec .categoryname p {
    margin-bottom: 0px;
  }

  .services .productwrap .divider .catsec {
    display: grid;
    grid-template-columns: repeat(7, 200px);
    overflow-x: scroll;
    text-align: center;
    gap: 1rem;
    align-items: center;
  }

  .services .productwrap .divider .productsec .productbox {
    padding: 20px !important;
  }

  .services .productwrap .divider .productsec .seoBoxContainer {
    padding: 20px;
  }
}

@media only screen and (max-width: 615px) {
  .services .banner .bannerdata h1 {
    font-size: 40px;
  }

  .services .banner .bannerdata {
    height: 200px;
  }

  .services .productwrap .divider {
    padding: 50px 20px;
  }
}

@media only screen and (max-width: 684px) {
  .services .productwrap .divider .productsec {
    grid-template-columns: 1fr;
  }

  .services .productwrap .divider .productsec .seoBoxContainer {
    grid-column: auto !important;
  }
}
