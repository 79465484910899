.bannerListing {
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  align-items: center;

  .bannerdata {
    width: 80%;
    height: auto;
    margin: 0 auto;
    .contentWrapper {
      width: 800px;
      height: 100%;
      padding: 100px 0px;
      h1 {
        color: white;
      }
      p {
        color: white;
        padding: 0px;
      }
      .btnWrapper {
        display: flex;
        gap: 0.5rem;
        button,
        a {
          background: white;
          padding: 8px 0px;
          border-radius: var(--border-radius);
          width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--color-red);
          text-decoration: none;
          cursor: pointer;
          border: 2px solid white;

          &:hover {
            font-weight: 600;
            background: var(--color-red);
            border: 2px solid white;
            color: white;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .bannerListing .bannerdata {
    width: 90%;
  }
  .bannerListing .bannerdata .contentWrapper {
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .bannerListing .bannerdata .contentWrapper {
    padding: 50px 0px;
  }
  .bannerListing .bannerdata .contentWrapper p {
    font-size: 16px !important;
  }
  .bannerListing .bannerdata .contentWrapper h1 {
    font-size: 28px !important;
  }
}
