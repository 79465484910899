.FabricLandPage {
  .productwrap {
    margin-bottom: 60px !important;
  }
  .Whyavon {
    padding: 100px 0px;
  }
}

@media only screen and (max-width: 580px) {
  .FabricLandPage {
    .Whyavon {
      padding: 50px 0px !important;
    }
  }
}
