.pagenotfound {
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    margin: 0px;
    padding: 0px;
    font-weight: 600;
  }
  h2 {
    margin: 0px;
    font-size: 40px;
    margin: 5px 0px 10px 0px;
  }
  a {
    text-decoration: none;
    color: var(--color-red);
    font-weight: 500;
    font-size: 24px;
    text-decoration: underline;
    &:hover {
    }
  }
}
@media only screen and (max-width: 578px) {
  .pagenotfound {
    height: 550px;
  }
}
