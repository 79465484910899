* {
  margin: 0;
  padding: 0;
}

:root {
  width: 100%;

  --color-white: #fff;
  --color-gray: #7f7f7f;
  --color-lgray: #9f9f9f;
  --color-llgray: #c8c8c8;
  --color-lllgray: #f1f1f1;
  --color-red: #ba0404;
  --color-inp: #f4f4f4;

  --color-orange: #f28204;
  --text-line-height: 1.4em;
  --heading-size: 32px;
  --font-size: 16px;
  --border-radius: 5px;
}

body,
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  padding: 0px !important;
  width: 100%;
}

p {
  font-family: "Montserrat", sans-serif;
  color: #272626;
  font-size: 18px !important;
}

.App {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
