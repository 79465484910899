.ceo {
    width: 100%;
    height: auto;
    background: #ebe9e9;

    .inner {
        width: 80%;
        margin: 0 auto;
        height: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
        padding: 50px 0px;
        align-items: center;
        gap: 2rem;

        .ceoimg {
            display: flex;
            flex-direction: column;
            justify-content: center;

            h1 {

                border-top: 3px solid var(--color-red);
                width: 200px;
                margin: 0 auto;
                margin-top: 13px;
                color: black;
                font-size: 30px;
                text-align: center;
            }
        }

        .content {
            .message {

                h1 {
                    color: var(--color-red);
                    font-weight: 600;
                }

                .lines {
                    border-top: 2px solid var(--color-gray);
                    padding: 20px 0px;

                    h2 {
                        color: var(--color-red);
                        font-weight: 600;
                        font-size: 26px;
                    }

                    p {
                        color: var(--color-gray);
                        font-weight: 600;
                        font-size: 16px !important;
                        margin: 0px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1348px) {

    .ceo .inner {
        width: 90% !important;
        align-items: flex-start;
    }

    .ceo .inner .ceoimg {
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 1137px) {
    .ceo .inner {
        width: 96% !important;
        grid-template-columns: 300px 1fr;
    }

    .ceo .inner .ceoimg img {
        transform: translateY(-30px);
    }
}

@media only screen and (max-width: 879px) {
    .ceo .inner {
        grid-template-columns: 1fr;
        position: relative;
    }

    .ceo .inner .ceoimg {
        position: absolute;
        opacity: .5;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%);

    }

    .content {
        z-index: 6666;
    }

    .ceo .inner .content .message .lines p {
        color: black;
    }
}

@media only screen and (max-width: 623px) {
    .ceo .inner .ceoimg {
        display: none;
    }
}