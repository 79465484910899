.Faq {
    width: 70%;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
    // margin-top: 30px;
    // margin-bottom: 30px;

    .headingsec {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-bottom: 30px;
        font-family: 'Montserrat', sans-serif;

        .heading {
            color: var(--color-red);
            font-size: 40px;
            font-weight: 800 !important;
        }

        .para {
            width: 500px;
            margin: 0 auto;
            font-size: 16px;
            font-weight: 500;
        }
    }


    .innermao {
        height: auto;
        margin-bottom: 20px;

        .inactive {
            border: 1px solid var(--color-red);
            background: var(--color-white);
            color: var(--color-red);
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-radius: 5px;
            padding: 15px 25px;
            cursor: pointer;

            .acctitle {
                margin: 0px;
                padding: 0px;

                h4 {
                    font-size: 18px;
                    font-weight: 600;
                    margin: 0px;
                }

            }

            .spanr {
                color: var(--color-red);
                font-size: 20px;
                font-weight: bolder;
            }
        }

        .wraper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-radius: 5px;
            padding: 15px 25px;
            cursor: pointer;
            background: var(--color-red);
            color: white;

            .acctitle {
                margin: 0px;
                padding: 0px;

                h4 {
                    font-size: 18px;
                    font-weight: 600;
                    margin: 0px;
                }

            }

            .spanr {
                font-size: 20px;
                font-weight: bolder;
            }

        }

        .accpara {
            background: white;
            margin: 0px;
            padding: 5px 40px;
            // text-align: justify;
            line-height: 2;

            p {
                margin-bottom: 5px;
            }
        }

        .hide {
            display: none;
        }

        .active {
            background: #e7f1ff;
        }
    }
}


@media only screen and (max-width: 772px) {
    .Faq {
        width: 90%;
    }

    .Faq .headingsec .para {
        width: 100%;
    }

    .Faq .headingsec .heading {
        margin-bottom: 0px;
    }

    .Faq .innermao .accpara {
        padding: 5px 10px;
    }

    .Faq .headingsec .heading {
        font-size: 28px;
    }

    .Faq .innermao .wraper .acctitle h4 {
        font-size: 16px;
    }

    .Faq .innermao .inactive .acctitle h4 {
        font-size: 16px;
    }
}

@media only screen and (max-width: 521px) {
    .Faq .innermao .accpara {
        text-align: left !important;
    }
}