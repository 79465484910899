.tyPage {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  .tyBox {
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 100px;
    h1 {
      color: var(--color-red);
      font-weight: 600;
      font-size: 50px;
    }
    p {
      color: var(--color-para);
      font-weight: 500;
    }
    button {
      width: 100%;
      border-radius: 5px;
      padding: 8px;
      outline: none;
      border: none;
      font-weight: 600;
      background: var(--color-red);
      color: white;
    }
  }
}
@media only screen and (max-width: 600px) {
  .tyPage {
    height: auto;
    margin: 100px 0px;
  }
  .tyPage .tyBox {
    padding: 50px;
  }
}
@media only screen and (max-width: 457px) {
  .tyPage .tyBox h1 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 457px) {
  .tyPage .tyBox {
    padding: 50px 20px;
  }
  .tyPage {
    margin: 50px 0px;
  }
}
