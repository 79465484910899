.footer {
  width: 100%;
  background: var(--color-llgray);
  display: flex;
  padding: 50px 0px;

  .innerfooter {
    width: 70%;
    margin: 0 auto;
    height: 80%;
    display: grid;
    grid-template-columns: 500px 1fr;
    align-self: center;
    grid-gap: 3rem;
    // justify-items: center;

    .flogo {
      margin-top: 10px;
      .infosec {
        // display: grid;
        p.formhedi {
          color: var(--color-red);
          margin-bottom: 0px;
          font-weight: bolder;
          font-size: 25px !important;
          display: none;
        }
        .map {
          display: grid;
          margin-top: 20px;

          iframe {
            width: 80%;
            height: 200px;
          }
        }

        .inner {
          display: flex;
          margin-top: 20px;

          .align {
            display: flex;
            align-items: center;
          }

          span {
            color: var(--color-red);
            font-size: 20px;
            margin-right: 20px;

            .icon {
              font-size: 22px;
            }
          }

          a {
            text-decoration: none;
            color: #272626;
            font-size: 17px;
            width: 80%;
            font-family: "Montserrat", sans-serif;
          }
        }
      }
      img {
        width: 180px;
        margin-bottom: 20px;
      }

      p {
        font-size: 14px;
        margin: 10px 0px;
      }

      .socialicon {
        margin-top: 25px;

        a {
          text-decoration: none;
          color: var(--color-red);
          font-size: 20px;
          margin-right: 10px;

          &:hover {
            color: black;
          }
        }
      }
    }

    .footercol {
      width: 100%;

      .formhedi {
        color: var(--color-red);
        font-size: 40px;
        margin-bottom: 0px;
        font-weight: bolder;
      }

      form {
        .contactinnerright {
          display: grid;

          .twofc {
            display: flex;
            grid-gap: 1rem;

            input {
              background: var(--color-inp);
              width: 100%;
              padding: 10px 10px;
              outline: none;
              border: none;
              margin-top: 20px;
              border-bottom: 1px solid var(--color-llgray);
              color: var(--color-gray);
              font-size: 16px;
              border-radius: 5px;
              font-family: "Montserrat", sans-serif;
            }
          }

          input {
            background: var(--color-inp);
            padding: 10px 10px;
            outline: none;
            border: none;
            margin-top: 20px;
            border-bottom: 1px solid var(--color-llgray);
            font-family: "Montserrat", sans-serif;
            color: var(--color-gray);
            border-radius: 5px;
          }

          textarea {
            background: var(--color-inp);
            padding: 10px 10px;
            outline: none;
            border: none;
            margin-top: 20px;
            border-bottom: 1px solid var(--color-llgray);
            color: var(--color-gray);
            max-height: 130px;
            min-height: 130px;
            border-radius: 5px;
            font-family: "Montserrat", sans-serif;
            resize: none;
          }

          .contactbtn {
            padding: 18px 10px;
            background: var(--color-red);
            color: white;
            border: none;
            outline: none;
            margin: 20px 0px;
            font-size: 16px;
            border-radius: 5px;
            font-weight: 600;
          }
        }
      }
    }

    .footerquote {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      h1 {
        font-size: 40px;
        font-weight: 600;
        position: relative;
        display: flex;
      }

      .quoteby {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
      }

      .icon1 {
        font-size: 20px;
        margin-right: 5px;
        color: var(--color-red);
      }

      .icon2 {
        font-size: 20px;
        margin-left: 5px;
        color: var(--color-red);
      }
    }
  }
}

.innerfooterquote {
  display: flex;
}

.formhedi {
  font-size: 30px !important;
  font-size: 800 !important;
}

@media only screen and (max-width: 1574px) {
  .footer .innerfooter {
    width: 80%;
    grid-template-columns: 400px 1fr;
  }
}

@media only screen and (max-width: 1280px) {
  .footer .innerfooter .footerquote h1 {
    font-size: 30px;
  }

  .footer .innerfooter .flogo p {
    font-size: 16px !important;
  }
  .footer .innerfooter {
    width: 90%;
    gap: 1rem;
  }
}

@media only screen and (max-width: 1135px) {
  .infosec {
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 1050px) {
  .footer .innerfooter {
    grid-template-columns: 350px 1fr;
  }
}
@media only screen and (max-width: 850px) {
  .footer .innerfooter {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  .footer .innerfooter .footercol form .contactinnerright .twofc {
    display: grid;
    gap: 0rem;
  }
  .footer .innerfooter .flogo .socialicon a {
    margin-right: 20px;
  }
  .flogo img {
    display: none;
  }
  .footer .innerfooter .flogo .infosec p.formhedi {
    display: block;
  }
  .footer .innerfooter .flogo {
    order: 2;
  }
  .footer .innerfooter {
    gap: 0rem;
  }
  .footer .innerfooter .flogo .infosec p.formhedi {
    margin-top: 0px;
  }
}
