.cta {
    width: 100%;
    // height: 100px;
    padding: 40px 0px;
    background: #272626;
    display: flex;
    justify-content: center;
    align-items: center;

    .inner {
        width: 900px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        h2 {
            color: white;
            font-size: 36px;
            font-weight: 500;
        }

        .btn {
            display: flex;
            justify-content: space-evenly;

            button {
                border: none;
                outline: none;
                padding: 10px 0px;
                width: 150px;
                color: white;
                background: var(--color-red);
            }
        }
    }
}



@media only screen and (max-width: 1165px) {
    .cta .inner {
        width: 100%;
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center;

        h2 {
            margin-bottom: 20px;
        }

        .btn {
            display: flex;
            gap: 20px;
        }
    }
}

@media only screen and (max-width: 1165px) {
    .cta .inner h2 {
        margin-bottom: 10px;
        font-size: 34px;
    }
}