.Aboutnewsec {
  width: 100%;
  height: auto;
  padding: 100px 0px;
  .inner {
    width: 1300px;
    height: auto;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 600px 1fr;
    justify-content: space-between;

    .img {
      width: 100%;
      display: flex;
      margin-left: auto;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      img {
        width: 100%;
      }
    }
    .rightsec {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 50px;
      .about {
        font-size: 30px;
        font-weight: 600;
        margin: 0px;
      }
      .abouth1 {
        font-size: 40px;
        font-weight: 600;
        margin: 0px;
        margin-bottom: 10px;
        color: #ba0404;
      }
      .aboutp {
        margin: 0px;
        p {
          font-size: 16px;
          font-weight: 500;
          text-align: justify;
          hyphens: auto;
          letter-spacing: 0.01em;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1550px) {
  .Aboutnewsec .inner {
    width: 80%;
    grid-template-columns: 550px 1fr;
    gap: 2rem;
  }
  .Aboutnewsec .inner .img {
    display: grid;
    justify-content: center;
    align-items: center;
    object-fit: cover;
  }
  .Aboutnewsec .inner .rightsec {
    padding: 0px;
  }
}

@media only screen and (max-width: 1300px) {
  .Aboutnewsec .inner {
    grid-template-columns: 480px 1fr;
    width: 85%;
  }
}
@media only screen and (max-width: 1100px) {
  .Aboutnewsec .inner {
    grid-template-columns: auto 1fr;
  }
  .Aboutnewsec .inner .img {
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 1000px) {
  .Aboutnewsec .inner {
    width: 90%;
  }
}

@media only screen and (max-width: 900px) {
  .Aboutnewsec .inner .img {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .Aboutnewsec {
    padding: 50px 0px;
  }
  .Aboutnewsec .inner .rightsec .aboutp p {
    font-size: 16px !important;
  }
  .Aboutnewsec .inner .rightsec .abouth1 {
    font-size: 30px;
  }
}
