.Rightimgsec {
  width: 100%;

  .rightinner {
    width: 70%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .textwrapper {
      display: grid;
      align-items: center;

      .inner {
        h1 {
          color: var(--color-red);
          font-size: 28px;
          font-weight: 500;
          line-height: 1.1em;

          span {
            font-size: 36px;
            font-weight: 600;
          }
        }

        p {
          font-size: 15px;
          width: 450px;
          line-height: 1.6em;
          margin: 10px 0;

          br {
            margin: 5px 0px !important;
          }
        }

        .seemore {
          background: var(--color-red);
          color: white;
          border: 1px solid var(--color-red);
          border-radius: var(--border-radius);
          padding: 8px 15px;
          transition: all 0.5s ease;

          &:hover {
            background: white;
            color: var(--border-radius);
          }
        }
      }
    }

    .imgwrapper {
      display: grid;
      display: flex;
      justify-content: flex-end;
      position: relative;

      .box {
        width: 80%;
        height: 100%;
        position: absolute;
        top: -10px;
        right: -10px;
        border: 2px solid var(--color-red);
      }

      img {
        width: 80%;
        // box-shadow: 12px -12px 11px #db5757;
        z-index: 5555;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .Rightimgsec .rightinner {
    width: 90%;
  }

  .Rightimgsec .rightinner .textwrapper .inner p {
    width: 100%;
  }
}

@media only screen and (max-width: 790px) {
  .Rightimgsec .rightinner {
    grid-template-columns: 1fr;
  }

  .Rightimgsec .rightinner {
    gap: 3rem;
  }

  .Rightimgsec .rightinner .imgwrapper img {
    width: 90%;
  }

  .Rightimgsec .rightinner .imgwrapper {
    justify-content: center;
  }

  .Rightimgsec .rightinner .imgwrapper .box {
    width: 90%;
    right: 18px;
  }
}

@media only screen and (max-width: 450px) {
  .Rightimgsec .rightinner .imgwrapper .box {
    right: 8px;
  }

  .Rightimgsec .rightinner .textwrapper .inner p {
    font-size: 14px;
  }

  .Rightimgsec .rightinner .imgwrapper img {
    width: 100%;
  }

  .Rightimgsec .rightinner .imgwrapper .box {
    width: 100%;
    right: -9px;
  }
}
