.Leftimgsec {
  width: 100%;
  padding: 100px 0px;
  .leftinner {
    width: 1300px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 600px 1fr;
    grid-gap: 2rem;
    align-items: center;

    .imgwrapper {
      position: relative;
      display: flex;

      .box {
        width: 80%;
        height: 100%;
        position: absolute;
        bottom: -10px;
        left: -10px;
        border: 2px solid var(--color-red);
        z-index: -1;
      }

      img {
        width: 80%;
        width: 100%;
      }
    }

    .textwrapper {
      display: grid;
      align-items: center;

      .inner {
        text-align: left;

        .h1 {
          color: var(--color-red);
          font-size: 28px;
          font-weight: 500;
          line-height: 1.1em;

          span {
            font-size: 36px;
            font-weight: 600;
          }
        }
        .dataWrapper {
          overflow-y: scroll;
        }
        p {
          font-size: 14px;
          // width: 450px;
          margin: 10px 0;
          text-align: justify;
          font-size: 15px;
          hyphens: auto;
          letter-spacing: 0.01em;
          line-height: 1.6em;
          &:nth-last-child(1) {
            margin-bottom: 0px;
          }
        }

        .seemore {
          background: var(--color-red);
          color: white;
          border: 1px solid var(--color-red);
          border-radius: var(--border-radius);
          padding: 8px 15px;
          transition: all 0.5s ease;

          &:hover {
            background: white;
            color: var(--border-radius);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1550px) {
  .Leftimgsec .leftinner {
    width: 80%;
    grid-template-columns: 500px 1fr;
  }
  .Leftimgsec .leftinner .imgwrapper {
    width: 100%;
    height: 100%;
  }
  .Leftimgsec .leftinner .imgwrapper img {
    object-fit: cover;
  }
}

@media only screen and (max-width: 1300px) {
  .Leftimgsec .leftinner {
    width: 85%;
    grid-template-columns: 450px 1fr;
  }
}
@media only screen and (max-width: 1000px) {
  .Leftimgsec .leftinner {
    grid-template-columns: 1fr;
    gap: 0px;
  }
  .Leftimgsec .leftinner .imgwrapper img {
    display: none;
  }
  .Leftimgsec .leftinner {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .Leftimgsec .leftinner {
    margin: 0px auto;
  }
  .Leftimgsec .leftinner .textwrapper .inner .h1 span {
    font-size: 30px;
  }
  .Leftimgsec .leftinner .textwrapper .inner p {
    font-size: 16px !important;
  }
  .Leftimgsec .leftinner .textwrapper .inner .dataWrapper {
    height: auto;
  }
  .Leftimgsec .leftinner .textwrapper .inner .dataWrapper {
    padding-right: 0px;
  }
  .Leftimgsec {
    padding: 50px 0px;
  }
}
