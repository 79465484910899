.CustomSlider {
    width: 80%;
    margin: 0 auto;

    .headingsec {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-bottom: 30px;


        .heading {
            color: var(--color-red);
            font-size: 32px;

            span {
                font-weight: 600;
            }
         
        }

        .para {
            width: 500px;
            margin: 0 auto;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .slide {
        width: 100%;

        // display: flex;
        // gap: 3rem;
        .slidebox {
            width: 500px;
            padding: 0px;
            outline: none;

            img {
                width: 100%;
            }

            h3 {
                color: var(--color-red);
                margin-left: 10px;
                a{
                    text-decoration: none;
                    color: var(--color-red);
                }
            }

            p {
                font-size: 14px;
                margin-left: 10px;


            }
        }
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
        display: flex;
        grid-gap: 1rem;
    }
}

@media only screen and (max-width: 1280px) {
    .CustomSlider {
        width: 90%;
    }
}

@media only screen and (max-width: 513px) {
    .CustomSlider .headingsec .para {
        width: 100%;
    }
    .CustomSlider {
        margin: 50px auto !important;
    }
}

