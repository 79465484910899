.blogdetailpage {
  width: 100%;

  .blogbanner {
    width: 100%;
    height: 350px;
    background: var(--color-llgray);
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      color: var(--color-red);
    }
  }

  .bloglistingpage {
    width: 1300px;
    height: auto;
    margin: 80px auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 400px));
    gap: 2rem;

    a {
      text-decoration: none;
    }

    .mapblogcont {
      height: auto;
      width: 100%;
      padding: 20px;
      box-shadow: 0 3px 10px #0000000f;
      border: 0.2px solid #01337924;
      display: grid;
      grid-template-rows: 1fr auto;
      &:hover {
        box-shadow: 0 3px 10px #00000073;
      }

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border: 0.2px solid rgba(1, 51, 121, 0.1411764706);
      }

      p {
        text-align: center;
        margin: 0px;
        padding-top: 10px;
        color: var(--color-red);
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
  .loader {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
      width: 150px;
      height: auto;
    }
  }
}
@media only screen and (max-width: 1550px) {
  .blogdetailpage .bloglistingpage {
    width: 80%;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
  .blogdetailpage .bloglistingpage .mapblogcont p {
    font-size: 17px !important;
  }
}
@media only screen and (max-width: 1300px) {
  .blogdetailpage .bloglistingpage {
    width: 85%;
  }
  .blogdetailpage .bloglistingpage .mapblogcont p {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 1130px) {
  .blogdetailpage .bloglistingpage {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
