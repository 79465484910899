.productwrap {
  min-height: 400px;
  max-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .productCont {
    width: 80%;
    margin: 50px 0px;
    .productsec {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
      gap: 1rem;
      .productbox {
        display: grid;
        flex-direction: column;
        box-shadow: 0 0 3px 1px #80808069;
        padding: 30px;
        border-radius: var(--border-radius);
        align-items: flex-start;
        gap: 1rem;
        a {
          text-decoration: none;

          .heading {
            color: var(--color-red);
            font-size: 22px;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 5px;
          }
        }

        .desc {
          font-size: 17px !important;
          line-height: 1.5em;
          margin: 0px;
        }
      }

      .button {
        padding: 8px 14px;
        font-weight: bold;
        background: var(--color-red);
        border: 1px solid var(--color-red);
        border-radius: var(--border-radius);
        color: #fff;
        outline: none;
      }
    }
  }
}
@media only screen and (max-width: 1280px) {
  .productwrap .productCont {
    width: 90%;
  }
}
@media only screen and (max-width: 586px) {
  .productwrap .productCont {
    margin-top: 0px;
  }
  .FabricLandPage .productwrap {
    margin-bottom: 0px !important;
  }
  .productwrap .productCont .productsec {
    grid-template-columns: 1fr;
  }
  .productwrap .productCont .productsec .productbox {
    padding: 20px;
  }
}
