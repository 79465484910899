.lisgingpopup {
  display: flex;
  align-items: flex-end;
  height: 100%;
  .quote {
    border-radius: var(--border-radius);
    border: none;
    outline: none;
    background: var(--color-red);
    color: white;
    padding: 8px 40px;
    border: 1px solid var(--color-red);
    font-size: 15px;
  }

  .title {
    color: var(--color-red);
    font-weight: 600;
    font-size: 20px;
  }
}

.myModal {
  .button {
    padding: 4px 0px;
    width: 100px;
    background: var(--color-red);
    color: white;
    border: none;
    outline: none;
    border-radius: var(--border-radius);
  }

  .close {
    margin-left: 20px;
    padding: 4px 0px;
    color: var(--color-red);
    width: 100px;
    background: var(--color-lgray);
    color: white;
    border: none;
    outline: none;
    border-radius: var(--border-radius);
  }

  .title {
    color: var(--color-red);
    font-weight: 600;
    font-size: 20px;
  }

  .description {
    textarea {
      min-width: 100%;
      max-width: 100%;
    }
  }

  .mobpara {
    margin-bottom: 20px;
  }

  .modalbody {
    display: grid;
    gap: 1rem;
    // padding: 30px 0px;

    input {
      background: var(--color-inp);
      padding: 10px 10px;
      outline: none;
      border: none;
      border-bottom: 1px solid var(--color-llgray);
      color: var(--color-gray);
    }

    textarea {
      background: var(--color-inp);
      padding: 20px 10px;
      outline: none;
      border: none;
      border-bottom: 1px solid var(--color-llgray);
      color: var(--color-gray);
      max-height: 130px;
      min-height: 130px;
    }
  }
}

.swal2-popup.swal2-modal.swal2-icon-error.swal2-show {
  z-index: 9999;
}

.fade.modal.myModal.modal.show {
  //   z-index: 2222 !important;
}
