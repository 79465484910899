.LetsWork {
  width: 70%;
  margin: 100px auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
  padding: 50px;
  .wrapper {
    .hediWrapper {
      position: relative;
      .hedi {
        font-size: 30px !important;
        font-weight: 600;
        color: var(--color-red);
        text-align: center;
        margin-bottom: 20px;
        &::after {
          content: "";
          position: absolute;
          background: var(--color-red);
          left: 50%;
          transform: translateX(-50%);
          bottom: 0px;
          width: 150px;
          height: 3px;
        }
      }
    }
    .threeColSec {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: center;
      align-items: center;
      justify-items: center;
      p {
        height: 36px;
        margin: 0px;
        font-weight: 600;
        width: 100%;
        border-right: 2px solid var(--color-red);
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-last-child(1) {
          border: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .LetsWork {
    width: 90%;
  }
}
@media only screen and (max-width: 1020px) {
  .LetsWork {
    padding: 30px;
  }
}

@media only screen and (max-width: 843px) {
  .LetsWork .wrapper .threeColSec {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
  }
  .LetsWork .wrapper .threeColSec p {
    width: 300px;
  }
  .LetsWork .wrapper .threeColSec p {
    border: none;
    // align-items: flex-start;
    justify-content: flex-start;
  }
  .LetsWork .wrapper .hediWrapper .hedi {
    text-align: left;
  }
  .LetsWork .wrapper .hediWrapper .hedi::after {
    left: 0px;
    transform: translateX(0%);
    width: 140px;
  }
}
@media only screen and (max-width: 500px) {
  .LetsWork .wrapper .threeColSec p {
    justify-content: flex-start;
    // border-left: 3px solid var(--color-red);
    padding-left: 10px;
  }
  .LetsWork .wrapper .threeColSec p:nth-last-child(1) {
    // border-left: 2px solid var(--color-red);
  }
}
