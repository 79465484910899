.seoCont {
  width: 1300px;
  margin: 100px auto;
  box-shadow: 0 0 3px 1px #80808069;
  padding: 30px;
  border-radius: var(--border-radius);
  height: 300px;
  overflow-y: scroll;
  h1,
  h2 {
    color: var(--color-red);
    font-size: 26px;
  }
  p {
    text-align: justify;
    hyphens: auto;
    letter-spacing: 0.01em;
    &:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }
}
@media only screen and (max-width: 1550px) {
  .seoCont {
    width: 80%;
  }
}
@media only screen and (max-width: 1300px) {
  .seoCont {
    width: 85%;
  }
}
@media only screen and (max-width: 600px) {
  .seoCont {
    margin: 50px auto;
    padding: 20px;
  }
  .seoCont p {
    font-size: 16px !important;
  }
  .seoCont h1,
  .seoCont h2 {
    font-size: 22px;
  }
}
