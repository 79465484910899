.Whyavon {
  width: 100%;
  background: var(--color-lllgray);
  padding: 80px 0px;

  .heading {
    text-align: center;
    color: var(--color-red);
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .avanmapdata {
    width: 1300px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;

    .simfstcol {
      display: flex;

      .icon {
        width: 60px;
        margin-right: 15px;
      }

      .divi {
        width: 80%;

        .headinginner {
          color: black;
          font-size: 18px;
          font-weight: 800;
          margin-bottom: 5px;
          text-align: left;
          color: var(--color-red);
        }

        .parainner {
          color: black;
          font-weight: 500;
          line-height: 1.6em;
          font-size: 16px;
          hyphens: auto;
          letter-spacing: 0.01em;
          text-align: justify;
        }
      }
    }
  }
  .sliderWrapper {
    .sliderSimfstCol {
      display: flex !important;
      width: 80% !important;
      margin: 0 auto;
      gap: 1rem;
      p.headinginner {
        color: var(--color-red);
        margin-bottom: 3px;
        font-weight: 600;
      }
      img {
        width: 50px;
      }
      p.parainner {
        hyphens: auto;
        letter-spacing: 0.01em;
        text-align: justify;
      }
    }
  }
}

@media only screen and (max-width: 1550px) {
  .Whyavon .avanmapdata {
    width: 80%;
  }
}
@media only screen and (max-width: 1300px) {
  .Whyavon .avanmapdata {
    width: 85%;
  }
}

@media only screen and (max-width: 800px) {
  .Whyavon .heading {
    width: 90%;
    text-align: left;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .Whyavon .sliderWrapper .sliderSimfstCol img {
    width: 60px;
  }
  p.headinginner {
    margin-top: 15px;
    font-weight: 600;
    color: var(--color-red);
  }
  .Whyavon .sliderWrapper .sliderSimfstCol {
    width: 90% !important;
  }
  .Whyavon .sliderWrapper .sliderSimfstCol p.headinginner {
    margin-top: 2px !important;
  }
}

@media only screen and (max-width: 600px) {
  .Whyavon {
    padding: 50px 0px;
  }
}

@media only screen and (max-width: 500px) {
  .Whyavon .sliderWrapper .sliderSimfstCol {
    flex-direction: column;
    gap: 0.5rem;
  }
  p.headinginner {
    margin-top: 2px;
    font-weight: 600;
    color: var(--color-red);
    margin-bottom: 9px;
  }
  .Whyavon .heading {
    font-size: 30px;
  }

  p.parainner {
    font-size: 16px !important;
  }
}
