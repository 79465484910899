.banner {
  width: 100%;
  height: 80vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);

  .bannerdata {
    color: var(--color-white);
    width: 550px;
    height: 240px;
    background: #ba0404;
    // opacity: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    p {
      margin-bottom: 5px;
      font-size: 18px;
      letter-spacing: 0.25em;
      color: white;
    }

    h5 {
      line-height: 1.1em;
      font-size: 44px;
      font-family: "Libre Caslon Text", serif;
    }

    button {
      margin-top: 15px;
      padding: 8px 25px;
      border-radius: var(--border-radius);
      background: none;
      color: white;
      font-size: 18px;
      text-decoration: none;
      border: 2px solid white;
      cursor: pointer;
      font-weight: 600;

      &:hover {
        background: white;
        color: var(--color-red) !important;
        border: 2px solid var(--color-red);
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width: 903px) {
  .banner .bannerdata {
    width: 500px;
  }

  .banner .bannerdata h5 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .banner {
    height: 80vh;
  }
}

@media only screen and (max-width: 550px) {
  .banner .bannerdata {
    width: 400px;
  }

  .banner .bannerdata h5 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 429px) {
  .banner .bannerdata {
    width: 350px;
    background: #ba0404c7;
    height:auto;
  }
  
}
