.productmain {
  width: 100%;
  position: relative;
}

.services .productwrap .divider .productsec .button {
  width: 100%;
}

.productmainpage {
  width: 70%;
  margin: 100px auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  max-height: auto;

  .inner {
    .productupper {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      gap: 5rem;

      .imagesec {
        width: 100%;
        height: 100%;

        img {
          width: 80%;
          border-radius: 20px;
        }
      }

      .summry {
        h1,
        h3,
        .h6 {
          color: var(--color-red);
          font-size: 30px;
          line-height: 1em;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          line-height: 1.6em;
          text-align: justify;
          hyphens: auto;
          letter-spacing: 0.01em;
        }

        .btnsec {
          display: flex;
          gap: 1rem;
          margin-top: 10px;

          button {
            border-radius: var(--border-radius);
            border: none;
            outline: none;
            background: var(--color-red);
            color: white;
            padding: 10px 40px;
            border: 1px solid var(--color-red);
            font-size: 15px;

            a {
              color: white;
              text-decoration: none;
            }

            &:hover {
              background: var(--color-lllgray);
              color: var(--color-red);
              font-size: 15px;
              border: 1px solid var(--color-red);

              a {
                color: var(--color-red);
                text-decoration: none;
              }
            }
          }
        }
      }
    }

    .descdiv {
      margin-top: 50px;

      h1,
      .h6 {
        color: var(--color-red);
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 30px !important;
      }

      .desc {
        p {
          font-family: "Montserrat", sans-serif;
          line-height: 1.6;
          color: black;
          text-align: justify;
          hyphens: auto;
          letter-spacing: 0.01em;
          font-weight: 500 !important;
          &:nth-child(2) {
            margin-top: 20px;
            margin-bottom: 10px;
            font-weight: 800;
          }

          strong {
            color: var(--color-red);
            font-size: 24px;
          }
        }

        ul {
          margin-left: 2px;
          display: grid;
          padding: 0px;

          li {
            font-family: "Montserrat", sans-serif;
            margin-left: 15px;
            margin-bottom: 10px;
            list-style-type: decimal;
            line-height: 1.6em;
          }
        }
      }
    }
    .seoCont {
      width: 100%;
      margin: 50px auto 0px;
      box-shadow: 0 0 3px 1px #80808069;
      padding: 30px;
      border-radius: var(--border-radius);
      height: 300px;
      overflow-y: scroll;
      h1,
      h2 {
        color: var(--color-red);
        font-size: 26px;
      }
      p {
        text-align: justify;
        hyphens: auto;
        letter-spacing: 0.01em;
        &:nth-last-child(1) {
          margin-bottom: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .productmainpage {
    width: 90%;
  }

  .productmainpage .inner .productupper {
    gap: 2rem;
  }
}

@media only screen and (max-width: 790px) {
  .productmainpage .inner .productupper .imagesec {
    order: 2;
  }

  .productmainpage .inner .productupper {
    grid-template-columns: 1fr;
  }

  .productmainpage .inner .productupper .imagesec img {
    width: 400px;
  }
}
@media only screen and (max-width: 600px) {
  .productmainpage {
    margin: 50px auto;
  }
  .productmainpage .inner .productupper .summry p {
    font-size: 16px !important;
  }
  .productmainpage .inner .descdiv .desc p {
    font-size: 16px !important;
  }
  .productmainpage .inner .descdiv .desc p strong {
    font-size: 20px !important;
  }
  .productmainpage .inner .descdiv .desc ul {
    padding: 0px;
  }
  .productmainpage {
    .inner {
      .seoCont {
        margin: 50px auto;
        padding: 20px;
      }
      .seoCont p {
        font-size: 16px !important;
      }
      .seoCont h1,
      .seoCont h2 {
        font-size: 22px;
      }
    }
  }
}

@media only screen and (max-width: 440px) {
  .productmainpage .inner .productupper .imagesec img {
    width: 100%;
  }

  .productmainpage .inner .descdiv .desc ul {
    margin-left: 5px;
  }
}
