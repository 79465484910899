.contact + .footer {
  .innerfooter {
    grid-template-columns: 450px 1fr;
    .innerfooterquote {
      justify-content: center;
      .footerquote {
        h1 {
          font-size: 40px;
        }
        .quoteby {
          font-size: 30px;
        }
      }
    }
  }
}
.contact {
  width: 1300px;
  margin: 0 auto;
  margin-top: 130px;
  margin-bottom: 80px;

  .heading {
    font-family: "Libre Caslon Text", serif;
    text-align: center;
    color: var(--color-red);
    font-size: 40px;
    margin-bottom: 20px;
    font-family: "Libre Caslon Text", serif;
  }

  .towseccol {
    display: grid;
    height: 100%;
    grid-template-columns: 30% 70%;

    .infosec {
      // display: grid;
      .map {
        display: grid;
        margin-top: 20px;

        iframe {
          width: 80%;
          height: 200px;
        }
      }

      .inner {
        display: flex;
        margin-top: 20px;

        .align {
          display: flex;
          align-items: center;
        }

        span {
          color: var(--color-red);
          font-size: 20px;
          margin-right: 20px;
          font-family: "Montserrat", sans-serif;

          .icon {
            font-size: 22px;
          }
        }

        a {
          text-decoration: none;
          font-family: "Montserrat", sans-serif;
          color: #272626;
          font-size: 17px;
          width: 80%;
        }
      }
    }

    form {
      .contactinnerright {
        display: grid;

        .twofc {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 1rem;

          input {
            background: var(--color-inp);
            padding: 10px 10px;
            border-radius: 5px;
            outline: none;
            border: none;
            margin-top: 20px;
            border-bottom: 1px solid var(--color-llgray);
            color: var(--color-gray);
            font-size: 16px;
            font-family: "Montserrat", sans-serif;
          }
        }

        input {
          background: var(--color-inp);
          padding: 10px 10px;
          border-radius: 5px;
          outline: none;
          border: none;
          margin-top: 20px;
          border-bottom: 1px solid var(--color-llgray);
          font-family: "Montserrat", sans-serif;
          color: var(--color-gray);
        }

        textarea {
          background: var(--color-inp);
          padding: 10px 10px;
          border-radius: 5px;
          outline: none;
          border: none;
          margin-top: 20px;
          border-bottom: 1px solid var(--color-llgray);
          color: var(--color-gray);
          max-height: 130px;
          min-height: 130px;
          font-family: "Montserrat", sans-serif;
          resize: none;
        }

        .contactbtn {
          padding: 10px 10px;
          background: var(--color-red);
          color: white;
          border: none;
          outline: none;
          margin: 20px 0px;
          font-size: 16px;
          border-radius: 5px;
          font-weight: 600;
        }
      }
    }
  }
}

@media only screen and (max-width: 1550px) {
  .contact {
    width: 80%;
  }
  .contact .towseccol {
    grid-template-columns: 400px 1fr;
  }
}

@media only screen and (max-width: 1380px) {
  .contact + .footer {
    .innerfooter {
      grid-template-columns: 450px 1fr;
      .innerfooterquote {
        justify-content: center;
        .footerquote {
          h1 {
            font-size: 30px;
          }
          .quoteby {
            font-size: 24px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .contact {
    width: 85%;
  }
  .contact .towseccol {
    grid-template-columns: 350px 1fr;
  }
}

@media only screen and (max-width: 1135px) {
  .contact .towseccol form .contactinnerright .twofc {
    grid-template-columns: 1fr;
  }

  .contact .towseccol form .contactinnerright .twofc input {
    margin-top: 0px;
  }

  .infosec {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 30px;
    .inner {
      &:nth-child(1) {
        margin-top: 0px !important;
      }
    }
  }
}

@media only screen and (max-width: 1035px) {
  .contact + .footer .innerfooter {
    grid-template-columns: 400px 1fr;
  }
  .contact .towseccol {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 980px) {
  .contact + .footer .innerfooter {
    grid-template-columns: 1fr;
  }
  .contact + .footer .innerfooter .innerfooterquote {
    margin-top: 50px;
  }
  .contact + .footer .innerfooter .flogo {
    order: 1 !important;
  }
  .contact + .footer .innerfooter .innerfooterquote {
    order: 2 !important;
  }
}
@media only screen and (max-width: 555px) {
  .contact .heading {
    font-size: 26px;
  }

  .contact .towseccol .infosec .inner {
    width: 100%;
  }
  .contact + .footer .innerfooter .innerfooterquote .footerquote h1 {
    font-size: 20px;
  }
  .contact + .footer .innerfooter .innerfooterquote .footerquote .quoteby {
    font-size: 22px;
  }
  .footer .innerfooter .footerquote .icon2 {
    font-size: 17px;
  }
  .footer .innerfooter .footerquote .icon1 {
    font-size: 17px;
  }
}
