.letsconnect {
    width: 100%;
    background: var(--color-lllgray);

    .connectform {
        width: 70%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .forminner {
            padding: 30px 0px;

            h1 {
                font-size: 32px;
                color: var(--color-red);

                span {
                    font-weight: 500;
                }
            }

            p {
                font-size: 13px;
                width: 80%;
                margin-top: 10px;

            }

            .form {
                .multiinp {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 1rem;
                    margin-top: 20px;


                    .inp {
                        padding: 10px 10px;
                        border-radius: var(--border-radius);
                        border: none;
                        outline: none;
                        font-size: 16px;
                    }
                }

                .textarea {
                    display: grid;
                    margin-top: 20px;
                    font-size: 16px;

                    .message {
                        border-radius: var(--border-radius);
                        border: none;
                        outline: none;
                        font-size: 16px;
                        padding: 10px 10px;
                        display: grid;
                    }
                }

                .getquotebtn {
                    margin-top: 20px;

                    background: white;
                    color: var(--border-radius);
                    border: 1px solid var(--color-red);
                    border-radius: var(--border-radius);
                    padding: 8px 15px;
                    transition: all .5s ease;
                    color: var(--color-red);

                    &:hover {
                        background: var(--color-red);
                        color: white;
                    }
                }
            }
        }

        .image {
            display: grid;
            position: relative;

            img {
                position: absolute;
                width: 150%;
                bottom: -104px;
                left: -137px;
                z-index: 999;
            }
        }

    }

    .videosec {
        width: 100%;
        height: 440px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        // background: red;
        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            opacity: 75%;


        }

        .inner {
            display: grid;
            width: 70%;
            height: 100%;
            // background: rebeccapurple;
            z-index: 99;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            grid-template-columns: repeat(2, 1fr);
            // justify-content: center;
            align-items: center;
            grid-gap: 4rem;

            .imagesec {
                display: grid;
                position: relative;
                align-items: center;

                img {
                    width: 100%;
                }

                .icon {
                    position: absolute;
                    width: 80px;
                    left: 50%;
                    transform: translate(-50%);

                }
            }

            .text {
                display: grid;
                align-items: center;

                h1 {
                    color: var(--color-red);
                    font-size: 28px;
                    font-weight: 500;
                    line-height: 1.1em;

                    span {
                        font-size: 36px;
                        font-weight: 600;
                    }
                }

                p {
                    font-size: 14px;
                    width: 80%;
                    margin: 10px 0;
                    font-weight: 800;
                }

                .seemore {
                    background: var(--color-red);
                    color: white;
                    border: 1px solid var(--color-red);
                    border-radius: var(--border-radius);
                    padding: 8px 15px;
                    transition: all .5s ease;
                    width: 100px;

                    &:hover {
                        background: white;
                        color: var(--color-red);


                    }
                }
            }

        }
    }
}