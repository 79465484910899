.outteam {
  width: 100%;
  height: auto;
  padding: 100px 0px;
  .hedi {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: var(--color-red);
    margin-bottom: 30px;
  }

  // .inner {
  //   width: 80%;
  //   height: 100%;
  //   margin: 0 auto;

  //   .bd {
  //     width: 100%;
  //     display: flex;
  //     justify-content: center;
  //     margin-bottom: 2rem;

  //     .card:hover {
  //       scale: 1.1;
  //       box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  //     }

  //     .card {
  //       padding: 20px;
  //       width: 280px;
  //       height: auto;
  //       align-items: center;
  //       border: none;
  //       transition: all 0.5s ease;
  //       box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  //       display: grid;
  //       grid-template-rows: 200px auto 60px;
  //       justify-items: center;

  //       img {
  //         width: 200px;
  //         height: 200px;
  //         border-radius: 50%;
  //       }

  //       .name {
  //         text-align: center;
  //         margin: 0px;
  //         font-weight: 800;
  //         font-size: 22px !important;
  //         color: var(--color-red);
  //         line-height: 1.1em;
  //         margin: 5px 0px;
  //       }

  //       .desic {
  //         text-align: center;
  //         margin: 0px;
  //         font-weight: 600;
  //       }
  //     }
  //   }

  //   .multicards {
  //     display: grid;
  //     justify-content: center;
  //     align-items: center;
  //     margin: 0 auto;
  //     gap: 2rem;
  //     margin-bottom: 2rem;
  //     grid-template-columns: repeat(3, 280px);

  //     .card:hover {
  //       scale: 1.1;
  //       box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  //     }

  //     .card {
  //       padding: 20px;
  //       width: 280px;
  //       height: auto;
  //       align-items: center;
  //       border: none;
  //       transition: all 0.5s ease;
  //       box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  //       display: grid;
  //       grid-template-rows: 200px auto 60px;
  //       justify-items: center;

  //       img {
  //         width: 200px;
  //         height: 200px;
  //         border-radius: 50%;
  //       }

  //       .name {
  //         text-align: center;
  //         margin: 0px;
  //         font-weight: 800;
  //         font-size: 22px !important;
  //         color: var(--color-red);
  //         line-height: 1.1em;
  //         margin: 5px 0px;
  //       }

  //       .desic {
  //         text-align: center;
  //         margin: 0px;
  //         font-weight: 600;
  //       }
  //     }
  //   }
  // }
  .inner2 {
    width: 1300px;
    height: 100%;
    margin: 0 auto;
  }
}

.flip {
  transform: scaleX(-1);
}
.outteamSliderSimfstCol {
  width: 280px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 335px;
  margin: 0 auto;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 50%;
      width: 80%;
    }
  }
  p.headinginner {
    margin: 0px;
    text-align: center;
    margin: 0px;
    font-weight: 800;
    font-size: 22px !important;
    color: var(--color-red);
    line-height: 1.1em;
    margin: 15px 0px 10px;
  }
  p.parainner {
    text-align: center;
    margin: 0px;
    font-weight: 600;
  }
}
.outteamSliderSimfstCol .slick-track {
  display: flex !important;
  gap: 1rem !important;
}
.outteamSliderSimfstCol .slick-slider .slick-track,
.slick-slider .slick-list {
  padding: 10px 0px !important;
}

@media only screen and (max-width: 1550px) {
  .outteam .inner2 {
    width: 80%;
  }
}

@media only screen and (max-width: 1300px) {
  .outteam .inner2 {
    width: 85%;
  }
}
@media only screen and (max-width: 1050px) {
  .sliderSimfstCol {
    width: 270px !important;
  }
  p.headinginner {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 1010px) {
  .slick-initialized .slick-slide {
    display: flex !important;
    justify-content: center !important;
  }
}

@media only screen and (max-width: 600px) {
  .outteam {
    padding: 50px 0px;
  }
}
