.banner {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);

  .bannerdata {
    color: var(--color-white);
    width: auto;
    background: #ba0404;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px 50px;

    .h1 {
      line-height: 1.1em;
      font-size: 44px;
      font-family: "Libre Caslon Text", serif;
    }
  }
}
@media screen and (max-width: 480px) {
  .services .banner .bannerdata {
    height: auto !important;
    padding: 40px 0px;
  }
  .banner {
    height: 70vh !important;
  }
  .banner .bannerdata .h1 {
    font-size: 27px;
    margin: 0px;
  }
}
