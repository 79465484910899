.sticky {
  position: fixed;
  top: 0;
  z-index: 9999;
  box-shadow: 0px -3px 20px 0px grey;
}

.navcont {
  height: 70px;
  width: 100%;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .innernav {
    align-items: center;
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    height: 70px;
    margin: 0 auto;
    position: relative;
    width: 1300px;
    img {
      height: 40px;
    }

    .navright {
      display: flex;
      justify-content: right;
      height: 100%;
      align-items: center;

      ul {
        display: flex;
        width: 100%;
        justify-content: space-between;
        height: 100%;
        margin: 0px;

        .navbtn {
          border: none;
          background: white;

          .contactbtn {
            background: var(--color-red);
            padding: 8px 0px;
            border-radius: var(--border-radius);
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            text-decoration: none;
          }
        }

        li {
          list-style: none;
          position: relative;
          height: 100%;
          align-items: center;
          display: flex;
          cursor: pointer;

          &:hover .abs {
            display: block;
          }

          .abouts {
            position: absolute;
            z-index: 9999;
            background: white;
            width: 100%;
            display: none;
            top: 70px;
            left: -20px;
            margin: 0px;
            padding: 0px;

            li {
              width: 200px;
              background: white;
              // padding: 20px 0px;
              padding-left: 20px;

              &:hover {
                font-weight: 600;
                // color: var(--color-red);
                background: var(--color-red);
                color: white;
                text-decoration: underline;
              }
            }
          }

          .abs {
            position: absolute;
            z-index: 9999;
            background: white;
            width: 100%;
            display: none;
            top: 70px;
            left: -20px;
            margin: 0px;
            padding: 0px;

            li {
              width: 200px;
              background: white;
              // padding: 20px 0px;
              padding-left: 20px;

              &:hover {
                font-weight: 600;
                // color: var(--color-red);
                background: var(--color-red);
                color: white;
                text-decoration: underline;
              }
            }
          }

          a {
            text-decoration: none;
            font-size: 15px;
            font-weight: 600;
            // margin-right: 58px;
            color: var(--color-gray);
            position: relative;

            &:hover {
              font-weight: 600;
              color: var(--color-red);
              text-decoration: underline;
              .subCategories {
                display: flex;
              }
            }
            .subCategories {
              display: none;
              position: absolute;
              z-index: 9999;
              background: white;
              width: 100%;
              top: 70px;
              left: -20px;
              margin: 0px;
              padding: 0px;
              flex-direction: column;
              left: 200px;
              top: 0px;
              a {
                li {
                  width: 200px;
                  background: white;
                  padding-left: 20px;
                  height: 70px;
                }
              }
            }
          }

          .active {
            font-weight: 600;
            color: var(--color-red);
            text-decoration: underline;
          }
        }
      }
    }

    .icons {
      display: flex;
      justify-content: flex-end;

      .icon {
        margin-left: 10px;
        background: var(--color-gray);
        width: 20px;
        height: 20px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        &:hover {
          background: var(--color-red);
        }

        a {
          font-size: 16px;
          text-decoration: none;
          display: flex;
          color: var(--color-white);
        }
      }
    }
  }

  .mobilewrap {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100px 1fr;
    /* justify-content: center; */
    justify-items: center;
    align-items: center;
    position: relative;
    box-shadow: 0px -3px 20px 0px grey;

    .micon {
      display: grid;
      font-size: 20px;
    }

    .mlogo {
      display: grid;

      img {
        width: 130px;
        transform: translateX(-40px);
      }
    }

    .mmenuhide {
      position: absolute;
      top: 70px;
      width: 100%;
      height: 100vh;
      background: white;
      z-index: 9999;
      left: -100%;
      transition: all 1s ease;

      ul {
        margin: 0px 40px;
        padding: 0px;

        li {
          list-style: none;
          margin-top: 10px;

          a {
            text-decoration: none;
            font-size: 15px;
            // font-weight: 600;
            margin-right: 58px;
            color: var(--color-gray);

            &:hover {
              font-weight: 600;
              color: var(--color-red);
              text-decoration: underline;
            }
          }

          .active {
            font-weight: 600;
            color: var(--color-red);
            text-decoration: underline;
          }
        }
      }
    }

    .mmenushow {
      position: absolute;
      top: 70px;
      width: 100%;
      height: calc(100vh - 70px);
      background: white;
      z-index: 9999;
      left: 0%;
      transition: all 1s ease;
      overflow-y: scroll;
      padding-bottom: 50px;

      ul {
        margin: 0px 40px;
        padding: 0px;

        li {
          list-style: none;
          margin-top: 10px;

          a {
            text-decoration: none;
            font-size: 15px;
            margin-right: 58px;
            color: var(--color-gray);
            font-weight: bold;

            &:hover {
              font-weight: 600;
              color: var(--color-red);
              text-decoration: underline;
            }
          }

          .active {
            font-weight: 600;
            color: var(--color-red);
            text-decoration: underline;
          }
        }
      }
    }
  }

  .mobilewrap .mmenushow ul li .contactbtnmob {
    padding: 8px 10px;
    color: white;
    border-radius: var(--border-radius);
    background: var(--color-red);
  }

  .mobilewrap .mmenuhide ul li .contactbtnmob {
    padding: 8px 10px;
    color: white;
    border-radius: var(--border-radius);
    background: var(--color-red);
  }

  .mobiledd {
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;

    p {
      margin: 0px;
      font-size: 20px;
    }
  }

  .dblock {
    display: block;
    margin: 0px;
    padding: 0px;
    margin-top: 10px !important;

    a {
      line-height: 1em;
    }
  }

  .dhide {
    display: none;
  }
}

@media only screen and (max-width: 1550px) {
  .navcont .innernav {
    width: 80%;
  }
}

@media only screen and (max-width: 1300px) {
  .navcont .innernav {
    width: 85%;
  }
  .navcont .innernav {
    grid-template-columns: 0.4fr 1.6fr;
  }
}
@media only screen and (max-width: 1280px) {
  .navcont .innernav {
    width: 90%;
  }
  .navcont .innernav .navright ul {
    padding-left: 0px;
  }
  .navcont .innernav .navright ul li a .subCategories {
    bottom: 210px;
    top: auto;
  }
}

@media only screen and (max-width: 1050px) {
  .navcont .innernav {
    grid-template-columns: auto 1fr;
    gap: 2rem;
  }
  .navcont .innernav .navright ul {
    justify-content: right;
    gap: 1.5rem;
  }
  .navcont .mobilewrap .mmenushow ul.dblock {
    margin: 0px 20px;
  }
  .navcont .mobilewrap .mmenushow ul.dblock .subCateMetal {
    margin: 0px 20px;
    margin-top: 29px;
    margin-bottom: -31px;
  }
  li.metalWrap {
    position: relative;
  }
  span.showMetalcat {
    position: absolute;
    right: -30px;
    z-index: 88;
  }
}
