.foundermessage {
  width: 100%;
  height: 700px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  ::-webkit-scrollbar {
    width: 2px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }

  .inner {
    width: 100%;
    height: 100%;
    background: #ffffffc7;
  }

  .content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    .contentinner {
      width: 1300px;
      height: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 0.7fr 1.3fr;

      .manimg {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        order: 2;

        img {
          height: 700px;
        }
      }

      .message {
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        .rel {
          position: relative;
          div {
            h1 {
              color: var(--color-red);
              font-size: 37px;
            }
          }

          p.founder {
            color: var(--color-red);
            font-weight: 600;
            font-size: 22px !important;
            margin-bottom: 5px;
            font-style: italic;
            &:nth-last-child(1) {
              color: black;
              font-size: 17px !important;
              font-weight: 500;
            }
          }

          .inner2 {
            width: 700px;
            padding: 0px;
            margin-bottom: 10px;
            overflow-y: scroll !important;
            position: relative;

            h1 {
              font-size: 30px;
              font-weight: 600;
              color: white;
              margin-bottom: 20px;
            }

            h2 {
              font-size: 24px;
              font-weight: 600;
              color: var(--color-red);
            }
            .icon1 {
              display: inline-block;
              margin-right: 6px;
              color: var(--color-red);
            }
            .icon2 {
              display: inline-block;
              margin-left: 6px;
              color: var(--color-red);
            }
            p {
              color: black;
              font-size: 18px;
              font-weight: 500;
              text-align: justify;
              hyphens: auto;
              letter-spacing: 0.01em;
              display: inline-block;
              margin: 10px 0px;
              &:nth-child(2) {
                display: inline;
              }
              &:nth-last-child(2) {
                display: inline;
              }
            }

            a {
              background: #e3e1e1e0;
              padding: 8px 0px;
              border-radius: var(--border-radius);
              width: 150px;
              display: flex;
              font-weight: 600;
              font-size: 18px;
              justify-content: center;
              align-items: center;
              color: var(--color-red);
              text-decoration: none;
              border: none;
              outline: none;
            }
            .icon {
              color: var(--color-red);
              display: inline;
            }
          }
          .abs {
            position: relative;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1550px) {
  .foundermessage .content .contentinner {
    width: 80%;
  }
}

@media screen and (max-width: 1300px) {
  .foundermessage .content .contentinner {
    width: 85%;
    grid-template-columns: 1fr 0.5fr;
  }

  .foundermessage .content .contentinner .manimg img {
    height: auto;
  }

  .foundermessage .content .contentinner .message .inner2 {
    width: 100%;
  }
  .foundermessage .content .contentinner .manimg img {
    width: 400px;
    height: auto;
  }
}

@media screen and (max-width: 1208px) {
  .foundermessage .content .contentinner .message .rel .inner2 {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .foundermessage .content .contentinner {
    grid-template-columns: 1fr;
    padding-top: 100px;
  }
  .foundermessage .inner {
    position: absolute;
  }
  .foundermessage {
    height: auto;
  }
  .foundermessage .content {
    position: relative;
  }
}

@media screen and (max-width: 600px) {
  .foundermessage .content .contentinner .message .rel .inner2 h1 {
    font-size: 30px;
  }
  .foundermessage .content .contentinner .message .rel .inner2 p {
    font-size: 16px !important;
  }
  .foundermessage .content .contentinner {
    padding-top: 50px;
  }
  .foundermessage .content .contentinner .message .rel .inner2 {
    padding: 0px;
  }
  .foundermessage .content .contentinner .manimg img {
    width: 100%;
  }
  .foundermessage .content .contentinner .message .rel .abs .founder {
    font-size: 20px !important;
  }
}
