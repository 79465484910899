.centercont {
    width: 100%;
    height: 200px;

    // color: rebeccapurple;
    .inner {
        width: 50%;
        margin: 0 auto;

        h1 {
            color: var(--color-red);
            text-align: center;
            font-size: 46px;
            margin-bottom: 8px;

        }

        p {
            font-size: 14px;
            text-align: center;
            width: 80%;
            margin: 0 auto;

        }
    }
}