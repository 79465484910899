.homebusinesswrapper {
  width: 100%;
  height: 100%;
  background: var(--color-lllgray);
  padding: 80px 0px;

  .homebusiness {
    width: 1300px;
    margin: 0 auto;

    .h1 {
      color: var(--color-red);
      text-align: center;
      margin-bottom: 60px;
    }

    .para {
      text-align: center;
      width: 700px;
      margin: 0 auto;
    }

    .setmentcats {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-around;
      align-items: center;
      gap: 2rem;
      margin-top: 30px;

      a {
        text-decoration: none;
        transition: all 0.5s ease;

        &:hover {
          scale: 1.03;
        }

        img {
          width: 100%;
          border-radius: var(--border-radius);
        }

        li {
          list-style-type: none;
          text-align: center;
          color: var(--color-red);
          font-weight: 600;
          font-size: 22px;
          margin-top: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1550px) {
  .homebusinesswrapper .homebusiness {
    width: 80%;
  }
  .homebusinesswrapper .homebusiness .h1 {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 1300px) {
  .homebusinesswrapper .homebusiness {
    width: 85%;
  }
  .homebusinesswrapper .homebusiness .h1 {
    font-size: 34px;
  }
}
@media only screen and (max-width: 1200px) {
  .homebusinesswrapper .homebusiness .setmentcats a li {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1000px) {
  .homebusinesswrapper .homebusiness {
    width: 90%;
  }
}
@media only screen and (max-width: 600px) {
  .homebusinesswrapper .homebusiness .setmentcats a li {
    font-size: 16px !important;
  }
  .homebusinesswrapper .homebusiness .setmentcats {
    gap: 1rem;
  }
  .homebusinesswrapper .homebusiness .h1 {
    margin-bottom: 20px;
  }
  .homebusinesswrapper .homebusiness .h1 {
    font-size: 30px;
  }
  .homebusinesswrapper .homebusiness .setmentcats {
    grid-template-columns: repeat(2, 1fr);
  }
  .homebusinesswrapper {
    padding: 60px 0px;
  }
}
