.blogdetailpage {
  width: 100%;
  height: auto;
  margin-bottom: 50px;

  .blogdetailbanner {
    width: 100%;
    height: 400px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .blogdetailwrapper {
    width: 1300px;
    margin: 0 auto;

    h1 {
      color: var(--color-red);
      font-weight: 600;
      font-size: 32px;
      line-height: 1.2em;
      margin: 50px 0px 10px 0px;
    }

    .inner {
      p {
        color: var(--color-para) !important;
        text-align: justify;
        hyphens: auto;
        letter-spacing: 0.01em;
        span {
          text-align: justify;
          font-size: 17px !important;
          color: var(--color-para) !important;
        }
        line-height: 1.8em;
        font-size: 17px !important;

        font-weight: 400;
        b,
        strong {
          font-size: 17px !important;

          color: var(--color-red);
          font-weight: 800;
        }
      }
      ol,
      ul {
        padding-left: 1rem;
        li {
          text-align: justify;
          -webkit-hyphens: auto;
          hyphens: auto;
          letter-spacing: 0.01em;
          line-height: 1.8em;
          font-size: 16px;
          font-weight: 400;
          color: var(--color-para) !important;
          b,
          strong {
            font-size: 17px !important;

            color: var(--color-red);
            font-weight: 500;
          }
        }
      }

      ul {
        padding: 0px;
        padding-left: 15px;
        margin: 10px 0px;
        color: var(--color-para);
        line-height: 1.8em;
      }

      h2,
      h3 {
        font-size: 26px !important;

        font-weight: 600 !important;
        margin: 10px 0px 10px 0px !important;
        color: var(--color-red) !important;
        margin: 20px 0px;
        span {
          font-size: 24px !important;
          font-weight: 600 !important;
          margin: 10px 0px 10px 0px !important;
          color: var(--color-red) !important;
          margin: 20px 0px;
        }
      }

      img {
        width: 900px;
        margin: 0 auto;
        display: flex;
      }
    }
  }
}
.loader {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .image {
    width: 200px;
    height: auto;
  }
}
@media only screen and (max-width: 1550px) {
  .blogdetailpage .blogdetailwrapper {
    width: 85%;
  }
}
@media only screen and (max-width: 1070px) {
  .blogdetailpage .blogdetailwrapper .inner img {
    width: 100%;
  }
}
@media only screen and (max-width: 1000px) {
  .blogdetailpage .blogdetailwrapper {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .blogdetailpage .blogdetailbanner {
    height: 250px;
  }
}

@media only screen and (max-width: 600px) {
  .blogdetailpage .blogdetailwrapper h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5em;
  }

  .blogdetailpage .blogdetailwrapper .inner h2 span {
    font-size: 22px !important;
  }
  .blogdetailpage .blogdetailbanner {
    height: 200px;
  }
  .blogdetailpage .blogdetailwrapper .inner p {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 480px) {
  .blogdetailpage .blogdetailwrapper h1 {
    font-size: 24px;
  }

  .blogdetailpage .blogdetailwrapper {
    width: 90%;
  }

  .blogdetailpage .blogdetailwrapper .inner ul {
    padding-left: 0px;
  }
  .blogdetailpage .blogdetailwrapper .inner h2,
  .blogdetailpage .blogdetailwrapper .inner h3 {
    font-size: 22px !important;
  }
}
